
// console.log('Hello Colorado from Webpacker date-picker-app...')
import Vue from 'vue/dist/vue.esm'
import dateFns from 'date-fns'
import TurbolinksAdapter from 'vue-turbolinks'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'

Vue.use(TurbolinksAdapter)
Vue.use(AirbnbStyleDatepicker)

document.addEventListener('turbolinks:load', () => {
  var element = document.getElementById("date-range-picker")
  if (element != null) {
    console.log('Setting up date-range-picker')
    var datePickerApp = new Vue({
      el: '#date-range-picker',
      data: {
        dateFormat: 'MMM D',
        inputDateOne: '',
        inputDateTwo: '',
        buttonDateOne: '',
        buttonDateTwo: '',
        inlineDateOne: '',
        sundayFirst: true,
        startOpen: false,
        alignRight: false,
        trigger: false,
        colors: {
            selected:       '#2a8cb7',
            inRange:        '#66e2da',
            selectedText:   '#fff',
            text:           '#565a5c',
            inRangeBorder: '#33dacd',
            disabled:       '#fff',
            hoveredInRange: '#67f6ee'
        },
      },
      created() {
        this.buttonDateOne = currentBeginDate;
        this.buttonDateTwo = currentEndDate;
        this.inputDateOne  = currentBeginDate;
        this.inputDateTwo  = currentEndDate;
      },
      methods: {
        formatDates: function(dateOne, dateTwo) {
          var formattedDates = ''
          if (dateOne) {
            formattedDates = dateFns.format(dateOne, this.dateFormat)
            $('#begin_date_view').html(dateFns.format(dateOne, "dddd, MMMM D"))
            $('#begin_date').val(dateOne)
          } else {
            $('#begin_date_view').html("")
            $('#begin_date').val("")
          }
          if (dateTwo) {
            formattedDates += ' - ' + dateFns.format(dateTwo, this.dateFormat)
            $('#end_date_view').html(dateFns.format(dateTwo, "dddd, MMMM D"))
            $('#end_date').val(dateTwo)
          } else {
            $('#end_date_view').html("")
            $('#end_date').val("")
          }
          if ($('#begin_date').val() != $('#prior_begin_date').val() || $('#end_date').val() != $('#prior_end_date').val()) {
            update_pricing()
          }
          return formattedDates
        },
        onClosed: function() {
          var datesStr = this.formatDates(this.buttonDateOne, this.buttonDateTwo)
          console.log('Dates Selected: ' + datesStr)
          this.trigger = false
          // Post to server for prices, rules.
        },
        onCancelled: function() {
          console.log('onCancelled called')
        },
        toggleAlign: function() {
          this.alignRight = !this.alignRight
        },
        triggerDatepicker: function() {
          this.trigger = !this.trigger
        },
        onMonthChange: function(dates) {
          // console.log('months changed', dates)
        },
      },
    })
  }
})
